//Login page styles
.textLabel {
    font-size: 16px;
    font-weight: 500;
    color: #241c15;
    padding-bottom: 10px;
    line-height: 18px;
}

.formComponents {
    padding: 15%;
}

.copyRightsDiv {
    font-size: 12px;
    flex: 1;
    padding: 12px 48px 12px 48px;
    display: flex;
    align-items: flex-end;
}

.loginContainer {
    min-height: 100%;
}

.forgotUsername {
    font-size: 13px;
}

.logoImage {
    max-height: 100px;
    max-width: 100%;
}

.logoContainer {
    flex: 1;
}

.formContainer {
    flex: 3;
    padding: 8px 48px 12px 48px;
}

.carousel-item {
    display: inline-block;
    transition: transform .0s ease-in-out !important;
}

.sliderDiv {
    right: 0;
    left: 35%;
}

.carousel-dots {
    position: absolute;
    bottom: 0;
    z-index: 500;
    background-color: #fff;
    opacity: 0.5;
    margin: unset;
    padding: 0.5rem 0 !important;
}

.bgFirstSlide,
.bgSecondSlide {
    background-image: url(https://react-slideshow.herokuapp.com/images/slide_7.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 100%;
    position: initial;
}

.bgSecondSlide {
    background-image: url(https://react-slideshow.herokuapp.com/images/slide_5.jpg);
}

//Login Page new Style

.login-page-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #f3f4f5;
    z-index: 1500;
    display: flex;
    justify-content: center;
    overflow: auto;
}

.login-page-div {
    width: 450px;
    //background: #fff;
    display: flex;
    align-items: center;
    flex-direction: column;
    z-index: 1510;
}

.logo-div {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 40px;
    margin-top: auto;
    background-color: #fff;
}

.logo-div img {
    padding: 0 10px;
}

.content-div .login-form-label {
    text-transform: unset;
    font-size: 13px;
    color: #676a6c;
}

.content-div {
    width: 300px;
}

.content-div .e-input-group:not(.e-disabled) .e-input-group-icon:hover,
.e-input-group.e-control-wrapper:not(.e-disabled) .e-input-group-icon:hover {
    background: transparent;
    color: #000;
}

.login-page-div .form-group {
    margin-bottom: 1.5rem;
}

.login-page-div .form-group div {
    margin: unset;
}

#rafdivRememberme .form-group {
    margin: unset;
}

.wrapper-content label {
    font-size: 0.8125em !important;
    font-weight: 600;
}

//login new style

.right-section img {
    width: calc(100% - 500px);
    position: fixed;
    left: 0;
    height: 100%;
}

.left-section {
    width: 500px;
}

.left-section .clientlogoImage {
    /*height: 60px;*/
    // height: 110px;
    height: 70px;
}

.left-section h3 {
    font-size: 20px;
    color: #666;
    letter-spacing: -.01em;
    line-height: 28px;
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: center;
    font-weight: 500;
}

.left-section h5 {
    font-size: 16px;
    color: #777;
    font-weight: 400;
    letter-spacing: -.01em;
    line-height: 28px;
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: center;
}

.rafawssignup .amplify-tabs__item {
    display: none;
}

@media only screen and (max-width: 550px) {
    .login-page-div {
        width: 90%;
    }

    .logo-div img {
        width: 75%;
    }

    .content-div {
        width: 75%;
    }

    .login-page-div .form-group {
        margin-bottom: 1rem;
    }

    .left-section {
        width: 100%;
    }

    .left-section .clientlogoImage {
        width: 100%;
        height: unset;
    }
}

@media only screen and (max-height: 499px) {
    .login-page-div {
        margin-top: 10%;
        height: 100%;
        margin-bottom: 10%;
    }
}

@media only screen and (min-width: 1600px) {
    .right-section img {
        width: 65%;
    }

    .left-section {
        width: 35vw;
    }
}

/*
@media only screen and (min-width: 1400px) {
    .login-page-div {
        width: 40%;
    }
    .logo-div{
        padding-top: 10%;
        padding-bottom: 5%;
    }
    .content-div .mb-4{
        margin-bottom: 7% !important;
    }
    .content-div .mb-5{
        margin-bottom: 10% !important;
    }
    .logo-div img {
        width: 65%;
    }
    .content-div {
        width: 65%;
    }

}*/
@import url(./variables.scss);

.rafSFGrid {
    padding-bottom: 0.25rem;
}

.rafSFGrid.e-grid .e-gridcontent .e-table.no-record,
.rafSFGrid.e-grid .e-gridcontent .e-table.no-record tbody,
.rafSFGrid.e-grid .e-gridcontent .e-table.no-record tbody tr,
.rafSFGrid.e-grid .e-gridcontent .e-table.no-record tbody tr td {
    display: block;
}

.rafSFGrid.e-grid.hide-tableHeader .e-gridheader {
    display: none !important;
}

.rafSFGrid.e-grid .e-gridcontent {
    // height: 100% !important;
    // overflow: auto;
    position: relative;
    background-color: transparent;
}

// .rafSFGrid.e-grid.hide-tableHeader .e-gridcontent {
//     border-top: 1px solid #eaeaea;
// }


/*.e-pivotview .e-grid .e-gridcontent .e-rowcell {
    background-color: #fff;
}*/

.rafSFGrid.e-grid .e-gridcontent .e-content {
    position: static !important;
    //border-left: 1px solid transparent;
}

.is-related-section .rafSFGrid.e-grid {
    padding: 0;
}

//.e-dashboardlayout .rafSFGrid.e-grid .e-gridcontent .e-content,
.is-related-section .rafSFGrid.e-grid .e-gridcontent .e-content {
    overflow-y: hidden !important;
}

//.e-dashboardlayout .rafSFGrid.e-grid .e-gridheader,
.is-related-section .rafSFGrid.e-grid .e-gridheader {
    padding-right: 0 !important;
    //border-right: 0 !important;
}

.e-card .is-related-section .rafSFGrid.e-grid .e-gridheader {
    border-top: 0px;
}

.e-card .is-related-section .rafSFGrid.e-grid .e-gridheader .e-headercontent {
    border-right: 0px !important;
}

.e-grid.e-default.e-bothlines .e-headercell:first-child {
    border-left: 0px;

}

.e-card .is-related-section .rafSFGrid.e-grid .e-gridcontent .e-table.e-tableborder {
    border-right: 0px;
}

/*.rafSFGrid.e-grid.e-default.e-bothlines .e-gridheader tr th:first-child {
    border-left: 1px solid #eaeaea;
}
.rafSFGrid.e-grid .e-gridcontent tr td:first-child {
    border-left: 1px solid #eaeaea;
}
.detailspage-outer-div .relatedListOuterDiv .rafSFGrid.e-grid .e-gridcontent tr td:nth-child(2) {
    border-left: 1px solid #eaeaea;
}*/
/*.rafSFGrid.e-grid.e-default.e-bothlines .e-gridheader tr th:last-child {
    border-right: 1px solid #eaeaea;
}*/
/*.rafSFGrid.e-grid .e-gridcontent tr td:last-child {
    border-right: 1px solid #eaeaea;
}*/
/*.rafSFGrid.e-grid.e-default.e-bothlines .e-gridheader .e-headercontent {
    border-left: 1px solid #eaeaea;
    border-right: 1px solid #eaeaea !important;
}*/
.relatedListOuterDiv .rafSFGrid.e-grid .e-gridcontent tr td:nth-child(2)::after,
.rafSFGrid.e-grid .e-gridcontent tr td:first-child::after,
.rafSFGrid.e-grid .e-gridcontent tr td:last-child::after {
    content: "";
    //border-right: 1px solid #eaeaea;
    // height: 100%;
    // position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
}

.rafSFGrid.e-grid .e-gridcontent tr td:last-child::after {
    right: 0;
    left: unset;
}

.rafSFGrid.e-grid .e-gridcontent tr.e-emptyrow td::after {
    content: unset;
}

.e-gridcontent-h-100 .rafSFGrid.e-grid .e-gridcontent {
    height: auto !important;
}

/*.rafSFGrid.e-grid .e-gridcontent tr.e-emptyrow td::after {
    content: unset;
}*/

.rafSFGrid .e-gridcontent tr.e-row td.e-rowcell {
    cursor: pointer;
    font-size: 14px;
    box-shadow: unset;
}

// .e-bigger .e-grid:not(.e-grid-min-height) .e-gridcontent tr td:not(.e-indentcell):first-child:empty,
// .e-bigger .e-grid:not(.e-grid-min-height) .e-gridcontent tr.e-row .e-rowcell:empty,
// .e-grid.e-bigger:not(.e-grid-min-height) .e-gridcontent tr td:not(.e-indentcell):first-child:empty,
// .e-grid.e-bigger:not(.e-grid-min-height) .e-gridcontent tr.e-row .e-rowcell:empty {
//     height: 4rem;
// }

.e-bigger .e-grid.e-row-responsive .e-gridcontent td[data-cell] {
    padding-top: 6px;
    padding-left: 40%;
    font-size: .875rem;
    font-weight: 500;
    color: var(--content-neutral-dark);
    word-break: break-word;
    // border-bottom: 1px solid var(--stroke-neutral-base);
}

.e-bigger .e-grid.e-row-responsive {
    .e-gridcontent tr {
        & td {
            display: none !important;
            /* Hide all table cells by default */
        }

        & td:nth-child(1),
        & td:nth-child(2),
        & td:nth-child(3) {
            display: block !important;
        }
    }

    &.rafSFGrid_4,
    &.rafSFGrid_5 {
        .e-gridcontent tr {
            & td:nth-child(4) {
                display: block !important;
            }
        }
    }

    &.rafSFGrid_5 {
        .e-gridcontent tr {
            & td:nth-child(5) {
                display: block !important;
            }
        }
    }
}

.e-bigger .e-grid.e-row-responsive .e-gridcontent td::before {
    width: 35%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    left: 13px;
    color: var(--content-neutral-base);
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    text-transform: uppercase;
    font-family: "Montserrat";
    top: 12px;
}

.rafSFGrid .e-gridcontent tr:hover td {
    background-color: #f4f4f4;
    color: #333;
}

/*.rafSFGrid .e-gridcontent tr td .e-checkbox-wrapper.e-css {
    vertical-align: middle;
}*/
.rafSFGrid .e-gridcontent tr.e-emptyrow:hover td {
    background-color: transparent;
}

.rafSFGrid .avatarWithText .avatarSib {
    padding-left: 10px;
}

.rafSFGrid .gridcheckboxclass .e-checkbox-wrapper .e-frame {
    width: 14px;
    height: 14px;
    //border: 1px solid #a6a6a6;
    line-height: 13px;
    margin-bottom: 4px;
}

.rafSFGrid .gridcheckboxclass .e-checkbox-wrapper .e-icons.e-check {
    font-size: 10px;
}

.rafSFGrid .gridcheckboxclass .e-checkbox-wrapper .e-icons.e-stop {
    font-size: 8px;
}

.rafSFGrid .e-headercelldiv .e-headertext {
    font-size: 0.75rem !important;
    line-height: 1rem !important;
    color: var(--content-neutral-base);
    font-weight: 400;
    /*font-weight: 400;*/
    font-size: 12px;
    /*color: #333;*/
}

/*.rafSFGrid .e-columnheader{
    height:40px;
}*/

.rafSFGrid .e-row {
    // height: 34px;
    z-index: 1;
    position: relative;
}

.task-grid .rafSFGrid.e-grid td.e-active {
    background-color: transparent;
}

.rafSFGrid.e-grid.e-default.e-bothlines .e-gridcontent tr.e-row.e-active td {
    background-color: #d1ebff;
}

.rafSFGrid.e-grid.e-default.e-bothlines .e-headercell {
    /* border-bottom-width: 2px; */
    background: #fff;
    /*height: 60px;*/
}

.rafSFGrid.e-grid:not(.ListGrid) {
    border: 0px;

    .e-gridheader,
    .e-gridcontent,
    .e-gridpager {
        border-left: 1px solid #eaeaea;
        border-right: 1px solid #eaeaea;
    }

    .e-gridcontent,
    .e-gridpager {
        border-bottom: 1px solid #eaeaea;
    }
}

.rafSFGrid.e-grid .e-gridheader .e-sortfilter .e-headercell:last-child {
    & .e-headercelldiv {
        padding-right: 30px;

    }

    & .e-sortfilterdiv {
        margin: -21px 8px;
    }
}

.e-grid .e-filterbarcell {
    background-color: var(--body-light-bg-color);
}

.e-grid .e-filterbarcell {
    .e-filterdiv {
        padding: 0.5rem 0;
    }

    input.e-input-group input.e-input,
    .e-input-group textarea.e-input,
    .e-input-group.e-control-wrapper input.e-input,
    .e-input-group.e-control-wrapper textarea.e-input,
    .e-input-group,
    .e-input-group.e-control-wrapper,
    .e-input-group.e-control-wrapper.e-disabled,
    .e-input-group.e-disabled,
    input.e-input,
    textarea.e-input,
    .e-input-group input,
    .e-input-group.e-control-wrapper input {

        min-height: 1.75rem;
    }

    .e-input-group .e-input-group-icon,
    .e-input-group.e-control-wrapper .e-input-group-icon {
        min-height: 1.75rem;
        min-width: 1.75rem;
    }
}

/*.detailspage-outer-div .e-grid.e-default.e-bothlines .e-headercell:nth-child(2),
.detailspage-outer-div .e-grid.e-default.e-bothlines .e-templatecell:nth-child(2) {
    border-left-width: 0;
}*/
/*.e-headercell.e-hide + .e-headercell {
    border-left-width: 0 !important;
}
.e-filterbarcell.e-hide + .e-filterbarcell {
    border-left-width: 0;
}
.e-rowcell.e-hide + .e-rowcell {
    border-left-width: 0;
}*/
.e-grid.rafSFGrid.e-row-responsive.e-responsive .e-templatecell:empty:after {
    content: 'Not set';
}

.rafSFGrid.e-grid .e-toolbar .e-toolbar-items {
    background: #fff;
}

// .rafSFGrid .e-res-toolbar.e-toolbar {
//     display: none;
// }


.rafSFGrid.e-grid .e-gridheader .e-headercontent .e-centeralign .e-headercelldiv.e-headerchkcelldiv {
    padding: unset;
}

// .rafSFGrid .e-pager .e-pagercontainer {
//     order: 1;
// }

// .rafSFGrid .e-pager div.e-parentmsgbar {
//     order: 3;
//     margin-left: auto;
//     display: inherit !important;
// }

.rafSFGrid .e-pager .e-pagesizes {
    order: 2;
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.rafSFGrid .e-pager .e-pagesizes .e-pagerdropdown {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 4px;
}

.rafSFGrid .e-pager .e-pagesizes .e-pagerconstant {
    margin: 0;
}

.rafSFGrid .e-pager .e-pagerdropdown {
    width: 75px;
}

.rafSFGrid .e-pager .e-pagerdropdown .e-control-wrapper {
    font-size: 12px;
    height: 26px;
    min-height: 26px;
    //margin-top: 4px;
}

.rafSFGrid .e-pager .e-pagerdropdown .e-dropdownlist.e-input {
    height: 24px;
    min-height: 24px;
}

.rafSFGrid .e-pager .e-pagerdropdown .e-ddl-icon.e-input-group-icon {
    min-height: 24px;
}


.rafSFGrid .e-control.e-toolbar {
    margin-bottom: 10px;
}

.rafSFGrid .e-control.e-toolbar.hidden {
    transition: ease-in 5s;
}

.rafSFGrid .e-control.e-toolbar {
    transition: ease-out 5s;
}

/*.rafSFGrid .e-grid .e-rowcell:first-child{
    padding-left:16px;
}*/

.rafSFGrid.e-grid .e-spinner-pane {
    display: none !important;
}

.rafSFGrid .e-headercontent tr th .e-checkbox-wrapper.e-css,
.rafSFGrid .e-gridcontent tr td .e-checkbox-wrapper.e-css {
    width: 14px;
    height: 14px;
    line-height: 14px;
}

#customSpinner:not(:empty) {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

#customSpinner .e-spinner-pane {
    position: static;
    height: 100%;
    background-color: #fff;
}

#customSpinner .e-spinner-pane .e-spinner-inner {
    //top: calc(20% - 40px) !important;
    transform: unset;
    position: static;
    width: 100%;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.e-columnheader .e-checkselectall {
    margin-left: 12px;
}

.e-grid .e-gridheader tr th:nth-child(2) span {
    padding-right: 0px;
}

/*.rafSFGrid .e-content {
    overflow-y: auto !important;
}*/

/*.rafSFGrid .e-gridheader {
    padding-right: 0px !important;
}*/

// .rafSFGrid .e-gridcontent .e-table,
.rafSFGrid .e-gridcontent .e-table.no-record {
    width: 100% !important;
}

// .e-bigger:not(.is-related-section) .e-grid.e-row-responsive .e-content .e-table {
//     background-color: #f3f4f5;

// }

.e-bigger .e-grid.e-row-responsive .e-content .e-table .e-row {
    margin-top: 0.5rem;
    background-color: #fff;
}

// .e-bigger .e-grid.e-row-responsive .e-content .e-table tbody {
//     margin-top: -0.5rem;
// }

.e-bigger .rafSFGrid.e-grid.e-row-responsive .e-content .e-table.no-record tbody {
    margin-top: 0;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

// .rafSFGrid.e-grid .e-gridheader .e-table.e-tableborder {
//     border: 0px !important;
// }

/*.rafSFGrid.no-record .e-gridcontent .e-table.no-record {
    width: 100% !important;
    display: block;
}

.rafSFGrid.no-record .e-gridcontent .e-table.no-record tbody {
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
}*/

.rightDialog .e-gridcontent .e-table.no-record,
.custom-right-section .e-gridcontent.e-table.no-record,
.taskDetails .e-gridcontent .e-table.no-record {
    width: auto !important;
}

// .rafSFGrid .e-gridcontent .e-table tr.e-emptyrow .empty-state-outer {
//     padding: 0.5rem;
// }

.rafSFGrid .e-gridcontent .e-table.no-record tr.e-emptyrow .empty-state-outer {
    //position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
    background-color: #fff;
}

.e-dashboardlayout .rafSFGrid .e-gridcontent .e-table.no-record tr.e-emptyrow .empty-state-outer,
.relatedListOuterDiv .rafSFGrid .e-gridcontent .e-table.no-record tr.e-emptyrow .empty-state-outer {
    position: static;
}

.hideEmptyState.rafSFGrid .e-gridcontent .e-table tr.e-emptyrow {
    display: none;
}

.rafSFGrid .e-control.e-tooltip {
    font-size: 13px;
}

.toolbarVisible {
    //transform: translatey(0);
    transition: opacity 1s linear, height 1s step-start;
    opacity: 1;
    min-height: 40px !important;
    height: 40px !important;
    margin-bottom: 16px;
}

.toolbarhidden {
    //transform: translatey(-9999px);
    transition: opacity 2s linear, step-end;
    /*transition-timing-function: linear, step-end;*/
    opacity: 0;
    height: 0px !important;
    min-height: 0px !important;
    margin-bottom: 0px;
    //border: unset;
}

.btndefault-icon {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
}

.e-btn.btndefault-icon:hover {
    background-color: rgba(0, 0, 0, 0.07) !important;
    border-color: #eaeaea !important;
    box-shadow: none !important;
}

.listPageContainer .e-grid thead th:first-child span,
.listPageContainer .e-grid thead th:nth-child(2) span {
    padding: unset;
}

.e-grid .e-focused:not(.e-menu-item) {
    box-shadow: unset;
}

//Grid page more menu style
.gridMoreMenuButton {
    background-color: transparent !important;
    width: 36px;
    height: 36px;
    padding: unset;
    border: 0 !important;
}

.gridMoreMenu {
    background-color: rgba(0, 0, 0, 0.3);
    box-shadow: unset;
    outline: unset;
}

.gridMoreMenu ul {
    min-width: unset;
    background-color: transparent;
    border: 0;
    box-shadow: unset;
}

.gridMoreMenu ul li {
    color: #fff;
    display: flex;
    align-items: center;
    margin-top: 5px;
    height: unset !important;
    font-size: 12px;
}

.gridMoreMenu.e-dropdown-popup ul .e-item .e-menu-icon {
    width: 30px;
    height: 30px !important;
    font-size: 10px;
    background-color: #0078d6;
    color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.gridMoreMenu.e-dropdown-popup ul .e-item:last-child {
    margin-bottom: 5px;
}

//Related list grid style changes
/*.detailsRightSection .e-grid.e-default tr td:first-child,
.detailsRightSection .e-grid.e-default tr th.e-headercell:first-child:not(.e-firstcell),
.detailsRightSection .e-grid.e-default tr th.e-detailheadercell:first-child,
.detailsRightSection .e-grid.e-default tr th.e-filterbarcell:first-child {
    border-left-width: 1px;
}
.detailsRightSection .e-grid.e-default tr:last-child td{
    border-bottom-width: 1px;
}
.detailsRightSection .e-grid.e-default tr td:last-child*/
/*.detailsRightSection .e-grid.e-default tr th:last-child */
/*{
    border-right-width: 1px;
}
.detailspage-outer-div .e-grid.e-default tr td:first-child,
.detailspage-outer-div .e-grid.e-default tr th.e-headercell:first-child:not(.e-firstcell),
.detailspage-outer-div .e-grid.e-default tr th.e-detailheadercell:first-child,
.detailspage-outer-div .e-grid.e-default tr th.e-filterbarcell:first-child {
    border-left-width: 1px;
}
.detailspage-outer-div .e-grid.e-default tr:last-child td {
    border-bottom-width: 1px;
}
.detailspage-outer-div .e-grid.e-default tr td:last-child*/
/*.detailspage-outer-div .e-grid.e-default tr th:last-child */
/*{
    border-right-width: 1px;
}*/
/*.detailspage-outer-div .rafSFGrid .e-headercontent {
    border-width: 0px !important;
}*/


.contactGrid {
    cursor: pointer;
}

.contactGrid .e-gridheader {
    display: none !important;
}


.e-grid .e-sortfilterdiv {
    margin: -21px -7px;
}

/*.rafsfgrid .e-headercell:hover {*/
/*background: rgba(244, 244, 244, 1);*/
/*background: #ddd !important;
}*/

/*.e-grid.e-resize-lines .e-headercell .e-rhandler:hover {
    border-right: 1px solid #eaeaea !important;
}*/
.rafSFGrid .e-btn .e-btn-icon {
    font-size: 12px;
}

.list-Container .e-gridheader.fixedHeader,
.list-Container-without-toolbar .e-gridheader.fixedHeader {
    //position: fixed;
    //top: 98px;
    background: #fff;
    z-index: 1000;
    //right: 24px;
    //left: 244px;
    // border: 0.0625em solid #e7e7e3;
    // border-right: 0;
    //border-left: 0;
}

.list-Container-without-toolbar .e-gridheader.fixedHeader {
    top: 105px;
}

.list-Container .e-pivotview .e-gridheader.fixedHeader {
    right: 25px;
    left: 245px;
}

.normal-detailspage .e-gridheader.fixedHeader {
    top: 113px;
}

.list-Container-without-toolbar .e-gridheader.fixedHeader:before {
    content: "";
    height: 26px;
    bottom: calc(100% + 0.0625em);
    background: #f3f4f5;
    position: absolute;
    left: -0.125em;
    right: -0.125em;
}

.list-Container .e-gridheader.fixedHeader.mini-navbar {
    left: 1.5rem;
    right: 1.5rem;
    top: 160px;
}

.list-Container .e-gridcontent.fixedHeader,
.list-Container-without-toolbar .e-gridcontent.fixedHeader {
    margin-top: 6.25em;
}

.list-Container .e-grid.e-row-responsive .e-gridcontent.fixedHeader,
.list-Container-without-toolbar .e-grid.e-row-responsive .e-gridcontent.fixedHeader {
    margin-top: 0;
}

/*.list-Container .e-gridcontent.e-responsive-header {
    height: calc(100vh - 310px) !important;
}*/
.rafSFGrid .custom_key_value {
    //min-width: 90px;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 14px;
    line-height: 18px;
    min-width: 90px;
    border-radius: 15px;
    min-height: 26px;
    height: 26px;
}

.rafSFGrid .custom_key_value.raf-icon-view {
    min-width: unset;
    padding-right: 0 !important;
}

.rafSFGrid .custom_key_value_sm .custom_key_value {
    min-width: 70px;
}

.rafSFGrid .custom_key_value.raf-outline {
    min-width: 0;
    height: unset;
    min-height: unset;
    line-height: normal !important;
    padding: 2px 8px !important;
}

.rafSFGrid.ListGrid .detailsLeftCard-second-title .detailsLeftCard-second-title-item .custom_key_value {
    padding: 1px 8px 2px;
    border-radius: 0.25rem;
    line-height: 26px;
    // min-width: unset;
}

.rafSFGrid .custom_key_value_outter {
    display: flex;
    // align-items: center;
    // justify-content: center;
}

.rafSFGrid.e-row-responsive .custom_key_value_outter {
    justify-content: start;
}

// .rafSFGrid .custom_key_value {
//     font-size: 10px;
//     line-height: 10px;
//     min-width: 60px;
// }

.rafSFGrid .colorIcon .custom_key_value {
    text-align: left;
    background-color: transparent;
}

.rafSFGrid .colorIcon i {
    font-size: 11px;
}

.rafSFGrid .e-gridheader .e-sortfilter th:nth-child(2) .e-headercelldiv {
    padding-right: 0;
}

.rafSFGrid .gridEditBtn {
    // display: none;
    visibility: hidden;
}

// .rafSFGrid .gridEditBtn button {
//     height: auto;
//     width: auto;
//     min-height: auto !important;
//     background-color: transparent !important;
//     border: 0px solid transparent !important;
//     line-height: normal;
//     padding: 0 !important;
// }

// .rafSFGrid .gridEditBtn button:hover {
//     background-color: transparent !important;
//     border: 0px solid transparent !important;
// }

.rafSFGrid .doc-gridContent {
    width: calc(100% - 20px);
}

.rafSFGrid .gridTempleteTextOuter {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.rafSFGrid .doc-gridContent .gridTempleteTextOuter {
    font-weight: 500;
    color: #0078D7;
}

.rafSFGrid .gridDefault:hover .gridEditBtn {
    // display: block;
    visibility: visible;
}

.rafSFGrid .gridDefault:hover .doc-gridContent {
    width: calc(100% - 100px);
}

.grid-toolbar-component {
    //padding: 1.4rem 1.5rem 1rem;
    //position: fixed;
    //top: 50px;
    //left: 220px;
    //right: 0px;
    z-index: 101;
    background-color: #f4f5f6;
    // padding-bottom: 0.75rem;
    border: 0;
    height: auto;
}

.grid-toolbar-component.remove-fixed-height {
    height: auto !important;
}

.grid-toolbar-component.top-nav-collapse {
    top: 0;
}

.normal-detailspage .grid-toolbar-component {
    height: auto;
}

.page-heading,
.detailspage-second-header,
.grid-toolbar-component {
    & .e-menu-wrapper {
        background-color: transparent;

        & ul.e-menu.e-control {
            padding: 0;
        }
    }
}

.page-heading .e-control.e-menu .e-menu-item,
.detailspage-second-header .e-control.e-menu .e-menu-item,
.grid-toolbar-component .e-control.e-menu .e-menu-item {
    height: 32px;
    line-height: 32px;
    padding: 0rem 2.25rem 0 0.75rem;
    background-color:
        #eaecef;
    font-weight: 500;
    font-size: 0.8125rem;
    border-radius: 0.5rem;
}

.page-heading .e-control.e-menu .e-menu-item .e-icons.e-caret,
.detailspage-second-header .e-control.e-menu .e-menu-item .e-icons.e-caret,
.grid-toolbar-component .e-control.e-menu .e-menu-item .e-icons.e-caret {
    height: 32px;
    line-height: 32px;
    right: 16px;
}

.page-heading .custom-button-lg.raf-iconbtn .e-control.e-menu .e-menu-item,
.detailspage-second-header .custom-button-lg.raf-iconbtn .e-control.e-menu .e-menu-item,
.grid-toolbar-component .custom-button-lg.raf-iconbtn .e-control.e-menu .e-menu-item {
    height: 2.5rem;
    line-height: 2.5rem;
}


.page-heading .e-control.e-menu .e-menu-item:hover,
.detailspage-second-header .e-control.e-menu .e-menu-item:hover,
.grid-toolbar-component .e-control.e-menu .e-menu-item:hover {
    background-color: #dcecf9;
    color: #0078D7;
    border-color: #dcecf9;
}

.page-heading .e-control.e-menu .e-menu-item:hover .e-icons.e-caret,
.detailspage-second-header .e-control.e-menu .e-menu-item:hover .e-icons.e-caret,
.grid-toolbar-component .e-control.e-menu .e-menu-item:hover .e-icons.e-caret {
    color: #0078D7;
}


.div-header-buttons {
    //background-color: #f7f9fc;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.375rem 1rem;
    width: 100%;
    height: auto;
    border-bottom: 1.5px solid #d3d7de;
}

body.scrolling-navbar.top-nav-collapse .header-menu-dropdown.e-menu-wrapper.e-popup.e-menu-popup {
    top: 75px !important
}

.header-menu-dropdown .e-menu-parent #Create.e-menu-item,
.header-menu-dropdown .e-menu-parent #CreateContact.e-menu-item {
    display: none;
}

.grid-toolbar-component .e-toolbar:not(.e-tab-header) {
    //border-width: 0px;
    //background-color: #004d8e;
    background-color: #fff;
    //border: 0;
    height: 75px !important;
}

.grid-toolbar-component.remove-fixed-height .e-toolbar {
    height: auto !important;
}

.normal-detailspage .grid-toolbar-component .e-toolbar {
    height: auto !important;
}

.grid-toolbar-component .e-toolbar .e-toolbar-items.e-tbar-pos .e-toolbar-left {
    padding-left: 1.25em;
    display: flex;
    align-items: center;
}

.grid-toolbar-component .e-toolbar .e-toolbar-items.e-tbar-pos .e-toolbar-right {
    padding-right: 1.25em;
    display: flex;
    align-items: center;
}

.grid-toolbar-component .e-toolbar .e-toolbar-items,
.grid-toolbar-component .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn {
    background-color: transparent;
}

.grid-toolbar-component .e-toolbar .e-toolbar-items .e-toolbar-left .e-toolbar-item .e-tbar-btn.e-btn {
    border-bottom: 0.1875em solid transparent;
    margin: 0 0.75em;
}

/*.grid-toolbar-component .e-toolbar .e-toolbar-items .e-toolbar-left .e-toolbar-item .e-tbar-btn.e-btn .e-tbar-btn-text {
    padding: 0 0px;
}*/
.grid-toolbar-component .e-toolbar .e-toolbar-items .e-toolbar-left .e-toolbar-item .e-tbar-btn.e-btn.active {
    border-bottom: 0.1875em solid #0078D7;
}

.grid-toolbar-component .e-toolbar .e-toolbar-items .e-toolbar-left .e-toolbar-item.active .e-tbar-btn.e-btn {
    border-bottom: 0.1875em solid #0078D7;
}

/*
.grid-toolbar-component .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn .e-tbar-btn-text,
.grid-toolbar-component .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn .e-icon-left,
.grid-toolbar-component .e-toolpop.e-toolbar .e-toolbar-items .e-toolbar-item .e-dropdown-btn,
.grid-toolbar-component .e-toolpop.e-toolbar .e-toolbar-items .e-toolbar-item .e-icon-left {
    color: #fff;
}
.grid-toolbar-component .e-toolbar .e-toolbar-items .e-toolbar-item:hover .e-tbar-btn.e-btn .e-tbar-btn-text,
.grid-toolbar-component .e-toolbar .e-toolbar-items .e-toolbar-item:hover .e-tbar-btn.e-btn .e-icon-left,
.grid-toolbar-component .e-toolpop.e-toolbar .e-toolbar-items .e-toolbar-item:hover .e-dropdown-btn,
.grid-toolbar-component .e-toolpop.e-toolbar .e-toolbar-items .e-toolbar-item:hover .e-icon-left {
    color: #0078D7;
}*/
.page-wrapper-Container.mini-navbar .grid-toolbar-component {
    left: 65px;
}

.toolbar-leftSection {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
}

.rafSFGrid .activityItems {
    border-bottom: 1px solid #e1ebef;
    border-top: none;
    padding: 10px 0px;
}

.rafSFGrid tr.e-filterbar .e-filterbarcell.e-fltrtemp .e-input-group .e-input-group-icon {
    font-size: 1em;
}

.rafSFGrid tr {
    cursor: pointer;
}

.rafSFGrid .swapTextwithHyperlink {
    cursor: pointer;
}

.rafSFGrid .e-gridheader.fixedHeader {
    overflow: unset;
}

.gridEditBtn .e-dropdown-btn.e-editbutton {
    padding: 8px !important;
}

.grid-hover-transparent .rafSFGrid .e-gridcontent .e-table .e-row:hover td {
    background-color: transparent;
}

// .rafSFGrid {
//     //display: contents !important;
//     display: flex;
//     flex-direction: column;
// }

// .rafSFGrid.e-grid .e-gridheader {
//     min-height: 45px;
// }
// .rafSFGrid.e-grid .e-gridheader .e-headercontent{
//     padding-bottom: 15px;
// }


.rafSFGrid .e-editbutton.documentBtn .e-menu-item .e-icons.e-caret {
    display: none;
}

.rafSFGrid .e-editbutton.documentBtn .e-menu-item.e-menu-caret-icon {
    padding-right: 0px !important;
}

.rafSFGrid .e-editbutton.documentBtn.e-popup-open .e-menu-parent {
    background: #fff;
    border: 1px solid #e3e3e7;
}

// .rafSFGrid .e-gridheader.e-droppable.hidden {
//     display: inline-block !important;
// }

.mailCenterModal .rafSFGrid .filterMessageBar,
.mailCenterModal .hover-table-div {
    margin-top: 15px;
}

.e-pivotview .e-row {
    height: 36px !important;
}

.e-pivotview .e-columnheader {
    height: 42px !important;
}

// @media only screen and (min-width: 768px)and (max-width: 1090px) {
//     /*.list-Container .e-gridcontent.e-responsive-header {
//         height: calc(100vh - 330px) !important;
//     }*/
//     // .rafSFGrid.e-grid .e-gridcontent {
//     //     height: calc(100% - 142px) !important;
//     // }

//     .rafSFGrid .e-table.no-record {
//         width: auto !important;
//     }
// }

// grid empty state style started


.listPage__container .grid-content-container.empty-grid,
.page-flex-container .grid-content-container.empty-grid {
    & .e-gridcontent .e-content {
        &::-webkit-scrollbar {
            display: none;
        }
    }

    & .empty-state-container {
        position: absolute;
        top: 0;
        bottom: 16px;
        overflow: auto;
        height: auto;
        width: auto;
        left: 0;
        right: 0;
        // display: block;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 1rem;
    }
}

.filterMessageBarInner {
    padding: 0.75rem;
    border: 1px solid var(--stroke-neutral-base);
    border-radius: .5rem;
    color: var(--content-neutral-dark);
    background-color: var(--surface-neutral-light);
    display: flex;
    align-items: center;
    /*margin-right: 12px;
    margin-left: 12px;
    margin-bottom: 1rem;*/
    font-size: 12px;
}

.filterMessageBarInnerItemContainer {
    display: flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 99px;
    background: var(--surface-neutral-base, #F5F5F5);
}

.filterMessageBarInnerItemLabel {
    color: var(--content-neutral-base, #686869);
    font: var(--body3-regular);
}

.filterMessageBarInnerItemValue {
    color: var(--content-neutral-dark, #0C0C0E);
    font: var(--body3-medium);
    padding-left: 0.25rem;
}

//filterMessage styles
.filterMessageBar {
    padding-bottom: 0.75rem;
}

button.e-btn.filterMessageBarButton {
    color: var(--content-state-danger-base) !important;
    background-color: transparent !important;
    border: 0 !important;
    height: auto !important;
    min-height: unset !important;
    max-height: unset !important;
    padding: 0 !important;
    margin-right: 1rem;
}

// grid empty state style end

@media only screen and (max-width: 992px) {

    // .detailspage-second-header .e-control.e-menu .e-menu-item,
    // .grid-toolbar-component .e-control.e-menu .e-menu-item {
    //     padding: 0;
    //     // height: 2.1875rem;
    //     // line-height: 2.1875rem;
    //     // width: 2.1875rem;
    //     // min-height: 2.1875rem;
    //     // min-width: 2.1875rem;
    // }

    // .detailspage-second-header .e-control.e-menu .e-menu-item .e-icons.e-caret,
    // .grid-toolbar-component .e-control.e-menu .e-menu-item .e-icons.e-caret {
    //     display: none;
    // }

    .detailspage-second-header .e-menu-wrapper ul .e-menu-item .e-menu-icon,
    .grid-toolbar-component .e-menu-wrapper ul .e-menu-item .e-menu-icon {
        height: 2rem;
        line-height: 2rem;
        width: 2rem;
        margin: 0;
    }
}

@media only screen and (max-width: 767px) {

    .list-Container .e-gridheader.fixedHeader,
    .list-Container-without-toolbar .e-gridheader.fixedHeader {
        //z-index: 3000;
        right: 1rem;
        left: 1rem;
        top: 137px;
    }

    .list-Container-without-toolbar .e-gridheader.fixedHeader {
        top: 105px;
    }

    .grid-toolbar-component {
        left: 0;
        padding-bottom: 1rem;
        height: 87px;
    }

    .rafSFGrid .gridEditBtn {
        visibility: visible;
    }

    .e-toolbar .e-toolbar-items .e-toolbar-left {
        padding-left: 12px;
    }

    .e-toolbar .e-toolbar-items .e-toolbar-right {
        padding-right: 12px;
    }

    /*.list-Container .e-gridcontent.e-responsive-header {
        height: calc(100vh - 280px) !important;
    }*/
    .rafSFGrid .e-table.no-record {
        width: auto !important;
    }


    .rafSFGrid .gridDefault .gridEditBtn {
        display: block;
    }

    .rafSFGrid .doc-gridContent {
        width: calc(100% - 100px);
    }

    .rafSFGrid .gridDefault:hover .doc-gridContent {
        width: calc(100% - 100px);
    }

    .rafSFGrid .e-pager .e-pagesizes {
        display: none !important;
    }
}

@media only screen and (min-width: 576px) {
    .rafSFGrid .e-pager {
        font-size: 12px;
        padding: 0;
        //border-top-width: 2px;
        line-height: normal;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

}

@media only screen and (max-width: 576px) {
    .grid-toolbar-component {
        padding-bottom: 0;
        height: auto;
    }

    .filterMessageBar {
        margin: 0px 0px 0px;
    }

    // .rafSFGrid.e-grid .e-gridcontent {
    //     height: calc(100% - 118px) !important;
    // }

    .rafSFGrid .e-table.no-record {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .list-Container .e-gridheader.fixedHeader,
    .list-Container-without-toolbar .e-gridheader.fixedHeader {
        //z-index: 3000;
        right: 0rem;
        left: 0rem;
        top: 80px;
    }

    .e-bigger .detailspage-second-header .e-control.e-menu .e-menu-item,
    .e-bigger .grid-toolbar-component .e-control.e-menu .e-menu-item,
    .detailspage-second-header .e-control.e-menu .e-menu-item,
    .grid-toolbar-component .e-control.e-menu .e-menu-item {
        padding: 0;
        height: 2rem;
        line-height: 2rem;
        width: 2rem;
        min-height: 2rem;
        min-width: 2rem;
    }

    .detailspage-second-header .e-control.e-menu .e-menu-item .e-icons.e-caret,
    .grid-toolbar-component .e-control.e-menu .e-menu-item .e-icons.e-caret {
        display: none;
    }

    .detailspage-second-header .e-menu-wrapper ul .e-menu-item .e-menu-icon,
    .grid-toolbar-component .e-menu-wrapper ul .e-menu-item .e-menu-icon {
        height: 2rem;
        line-height: 2rem;
        width: 2rem;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .header-menu-dropdown .e-menu-parent #Create.e-menu-item,
    .header-menu-dropdown .e-menu-parent #CreateContact.e-menu-item {
        display: block;
    }
}

.e-bigger .e-row-responsive.e-device .e-row {
    height: auto;
    // padding: 10px 0px;
}

.e-bigger .e-row-responsive.e-device .e-gridcontent tr td:first-child,
.e-bigger .e-row-responsive.e-device .e-gridcontent tr td:last-child,
.relatedListOuterDiv .e-row-responsive.e-device .e-gridcontent tr td:first-child::before,
.relatedListOuterDiv .e-row-responsive.e-device .e-gridcontent tr td:last-child::after {
    //border-left: none !important;
    //border-right: none !important;
    background: transparent !important;
}

.e-bigger .e-row-responsive.e-device.ListGrid .e-gridcontent tr td:first-child {
    padding: 0.75rem;
    min-height: 38px;
    // background-color: rgba(207, 213, 235, 0.4) !important;
}

.e-bigger .e-row-responsive.e-device.ListGrid .e-gridcontent tr:not(.e-emptyrow) td:first-child {
    padding: 0;
}

.e-bigger .e-row-responsive.e-device.ListGrid .e-gridcontent tr td:first-child::before {
    content: unset;
}

.e-bigger .e-row-responsive.e-device .e-gridcontent tr:hover td {
    background: none;
}

.e-bigger .e-row-responsive.e-device .custom_dropdown {
    justify-content: start !important;
}

.e-bigger.e-dialog .e-dlg-header-content .e-btn.e-dlg-closeicon-btn {
    left: 2px;
    top: 2px;
}

.e-bigger.e-responsive-dialog .e-dlg-header-content .e-dlg-header .e-res-custom-element .e-res-apply-btn {
    font-size: 16px;
    padding-top: 8px;
}

/*.e-popup.e-popup-flmenu {
    display: none;
}*/
/*.e-flmenu .e-autocomplete {
    opacity: .5;
    pointer-events: none;
    touch-action: none;
} */

.e-daterangepick-mob-popup-wrap {
    z-index: 5000;
}

//List grid style started
.list-grid-outter {
    height: calc(100% - 50px);
}

.rafSFList {
    background-color: #fff;
    border: 1px solid #eaeaea;
}

.rafSFList .e-list-parent {
    padding: 0;

    & .e-list-item {
        border-bottom: 1px solid #eaeaea;
    }
}

.rafSFList .e-list-parent .e-list-item:last-child {
    border-bottom: 0px solid #eaeaea;
}

//List grid style end

//List column grid style start

.rafSFList.rafColumnList {
    background-color: transparent;
    border: unset;
}

.rafSFList.rafColumnList .e-list-parent {
    display: flex;
    flex-wrap: wrap;
    margin: -16px -8px 0px -8px;
}

.rafSFList.rafColumnList .e-list-item {
    flex: 0 0 auto;
    // min-width: 300px;
    width: 25%;
    padding: 0 8px;
    margin-top: 16px;
    border: unset;
    background-color: transparent;
    // max-width: 100%;
}

.rafSFList.rafColumnList .e-list-item:hover {
    background-color: transparent;
}

.rafSFList .e-list-parent .e-list-item {

    & .hover-child-div {
        display: none;
    }

    & .hover-none-child-div {
        display: block;
    }

    & .hover-show-child-div {
        visibility: hidden;
    }

    & .hover-hide-child-div {
        visibility: visible;
    }

    &.e-hover {

        & .hover-child-div {
            display: block;
        }

        & .hover-none-child-div {
            display: none;
        }

        & .hover-show-child-div {
            visibility: visible;
        }

        & .hover-hide-child-div {
            visibility: hidden;
        }
    }
}


.e-row-responsive {
    &.rafSFGrid.e-grid {
        border: 0;

        & .e-gridcontent {
            background-color: transparent;
            // padding: 0 0.5rem;

            & .e-content {
                background-color: transparent;
            }

            & .e-table {
                background-color: transparent;

                & .e-row {

                    border: 1px solid #e0e0e0;
                    border-radius: 0.3125rem;
                    // padding-top: 0;
                }
            }
        }
    }
}

//qb-section style start

.qb-section {
    transform: translatex(100%);
    transition: transform 0.5s ease;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: #fff;
    z-index: 2000;
    filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.25));
    width: 450px;
}

.qb-section.show {
    transform: translatex(0%);
}

.divGridParent {

    transition: margin 0.5s ease;
}

//qb-section style end

// ListGrid style start 
.ListGrid.e-grid {
    border: 0px;
    padding-bottom: 0px;
}

.ListGrid.e-grid .e-table {
    background-color: #f3f4f5;
}

.ListGrid.e-grid .e-gridcontent {
    border-top: 0;

    & .e-content {
        //This style added to remove the empty scrollbar in the list grid
        overflow-y: auto !important;
        // padding: 0.5rem;
    }
}

.ListGrid.e-grid .e-gridcontent .e-table td {
    border-top: 0;
    padding: 0px;
}

.e-bigger .ListGrid.e-grid.e-row-responsive .e-content .e-table .e-row {
    padding: 0;
    margin: 0;
    background-color: transparent;
    border: unset;
}

.listGridItemOutter {
    padding-top: 0.75rem;
    // background-color: #f3f4f5;
    background-color: var(--body-light-bg-color);
}

.e-bigger .ListGrid.e-grid.e-row-responsive .e-content .e-table .e-row:first-child .listGridItemOutter {
    padding-top: 0;
}

.listGridItemContent {
    border: 1px solid #ddd;
    border-radius: 0.5rem;
    background-color: #fff;
    overflow: hidden;
}

.listGridItem {
    padding: 0.75rem;
    margin-top: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 0.5rem;
    background-color: #fff;
}

.listGridItemOutter .listGridItem {
    margin-top: 0;
    border: unset;
    border-radius: unset;
}

.ListGrid.e-grid.removeFirstRowMargin:not(.e-row-responsive) .e-gridcontent .e-table tr:first-child td .listGridItemOutter {
    padding-top: 0;
}

.ListGrid.e-grid .e-gridcontent .e-table tr:last-child td .listGridItemOutter {
    padding-bottom: 0.75rem;
}

.listGridItemHeader {
    border-bottom: 1px solid var(--stroke-neutral-base);
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.listGridItemFooter {
    border-top: 1px solid #ddd;
    padding: 0.5rem 1rem;
    background-color: #f8f9f8;
}

// ListGrid style end

//Card Grid style start
.e-bigger .ListGrid.CardGrid.e-grid,
.ListGrid.CardGrid.e-grid {
    & .e-gridcontent {
        background-color: transparent;

        & .e-content {
            overflow-x: hidden;
            background-color: transparent;

            & .e-table {
                background-color: transparent;

                & tbody {
                    display: grid;
                    // grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
                    gap: 16px;

                    & tr {
                        display: block;
                        width: 100%;
                        height: 100%;

                        & td {
                            display: block;
                            width: 100%;
                            background-color: transparent !important;
                            overflow: visible;

                            & .listGridItemOutter {
                                padding-top: 0;
                                background-color: transparent;

                                & .listGridItemContent {
                                    border-color: var(--stroke-neutral-base);
                                    // box-shadow: var(--box-shadow-lg);
                                }

                                & .listGridItem {
                                    padding: 1rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    & .e-res-toolbar.e-toolbar {
        display: none;
    }
}

.e-bigger .e-grid {
    padding-bottom: 1px;

    & .e-res-toolbar {
        border-left: 1px;
        border-right: 1px;
    }
}

//Card Grid style end

//schedule grid border change start 

.e-schedule .e-timeline-view .e-content-wrap table tr:last-child td,
.e-schedule .e-timeline-month-view .e-content-wrap table tr:last-child td {
    border-bottom-width: 1px;
}

//schedule grid border change end


.grid-header-button {
    // display: none;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 100;
}

.qb-section-outter:hover .grid-header-button {
    display: block;
}

//remove_grid_border style start 

.remove_grid_border.rafSFGrid {
    border: 0px;

    & .e-gridheader {
        border-top: 0;
        border-bottom: 0;

        & .e-headercell .e-rhandler {
            border-right: 0;
        }
    }

    & .e-gridcontent {
        border: 0;
    }
}

//remove_grid_border style end

.card-table {
    width: 100%;

    tr {
        display: flex;
        justify-content: space-between;
        border-radius: 0.5rem;
        // padding: 0.25rem 0.75rem;
        margin: 0 -0.5rem 1rem -0.5rem;
        border: 1px solid var(--stroke-neutral-base);
        padding: 0;

        &.header {
            padding-top: 0;
            padding-bottom: 0;
            border: 0;
            margin-bottom: 0;
        }
    }

    /* Style for table header */

    th {
        width: 12.5%;
        max-width: 12.5%;
        min-width: 100px;
        padding: 0.5rem;
        /* Adjust according to the number of headers */
        text-align: left;

        &.full-width {
            width: 100%;
            max-width: unset;
            min-width: unset;
        }

        &.raf-md {
            width: 20%;
            max-width: 20%;
            min-width: 130px;
        }

        &.raf-lg {
            width: 25%;
            max-width: 25%;
            min-width: 130px;
        }
    }

    /* Style for table data */
    td {
        /* Adjust according to the number of headers */
        width: 12.5%;
        max-width: 12.5%;
        min-width: 100px;
        padding: 0.75rem;
        border: none;

        &.full-width {
            width: 100%;
            max-width: unset;
            min-width: unset;
        }

        &.footer_td {
            width: 100%;
            display: block;
            padding: 0.5rem 1rem;
        }

        &.raf-md {
            width: 20%;
            max-width: 20%;
            min-width: 130px;
        }

        &.raf-lg {
            width: 25%;
            max-width: 25%;
            min-width: 130px;
        }
    }

    /* Add margin between cells */
    td:not(:last-child),
    th:not(:last-child) {
        margin-right: 10px;
    }
}

.raf_transparent_table.rafSFGrid {
    border: 0px;
    background-color: transparent;

    &.e-grid {
        & table {
            background-color: #fafafa;
        }

        & .e-gridheader {
            background-color: transparent;
            border: 0;

            & .e-headercontent {
                // border-left: 1px solid var(--stroke-neutral-base);
                border: 0;

                & .e-headercell {
                    background-color: #fafafa;
                    padding: 0 1rem;
                }
            }
        }

        & .e-filterbar {
            & .e-filterbarcell {
                border-top: 0;
                padding-bottom: 1rem;
                background-color: #fafafa;
            }
        }

        & .e-content {
            background-color: transparent;

            & .e-table {
                background-color: transparent;

                & tr.e-row td.e-rowcell {
                    height: 3.5rem;
                    font-size: 1rem;
                    line-height: 1.5rem;
                    color: var(--content-neutral-dark) !important;
                }
            }
        }
    }
}

.firstColumnHeaderPadding.rafSFGrid {
    &.e-grid {
        & .e-gridheader {
            & .e-headercontent {
                & .e-headercell:first-child {
                    & .e-headercelldiv {
                        padding-left: 2.5rem;
                    }
                }
            }
        }

        & .e-filterbar {
            & .e-filterbarcell:first-child {
                padding-left: 3rem;
            }
        }
    }
}

.brand_color_table {
    &.rafSFGrid {
        &.e-grid {
            & .e-content {
                & .e-table {
                    & tr {
                        &:nth-child(1n) {
                            & .brand_badge {
                                background-color: var(--surface-brand-primary-light);
                                color: var(--content-brand-primary-base);

                                & svg {
                                    fill: var(--content-brand-primary-base);
                                }
                            }
                        }

                        &:nth-child(2n) {
                            & .brand_badge {
                                background-color: var(--surface-brand-secondary-light);
                                color: var(--content-brand-secondary-base);

                                & svg {
                                    fill: var(--content-brand-secondary-base);
                                }
                            }
                        }

                        &:nth-child(3n) {
                            & .brand_badge {
                                background-color: var(--surface-brand-tertiary-light);
                                color: var(--content-brand-tertiary-base);

                                & svg {
                                    fill: var(--content-brand-tertiary-base);
                                }
                            }
                        }

                        &:nth-child(4n) {
                            & .brand_badge {
                                background-color: var(--surface-brand-quaternary-light);
                                color: var(--content-brand-quaternary-base);

                                & svg {
                                    fill: var(--content-brand-quaternary-base);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}

.table_striped {
    & tbody {
        & tr:nth-child(odd) .table_striped_item {
            background-color: var(--surface-neutral-light);

            & .table_striped_item_inner_card {
                background-color: var(--surface-neutral-base);
            }
        }

        & tr:nth-child(even) .table_striped_item {
            background-color: var(--surface-neutral-base);

            & .table_striped_item_inner_card {
                background-color: var(--surface-neutral-light);
            }
        }
    }
}

.table_row_card.rafSFGrid {
    background-color: #fff;

    & tr {
        &.e-columnheader {
            height: auto;

            & th.e-headercell {
                height: auto;
                background-color: var(--surface-neutral-base) !important;
                background-image: unset !important;
                border-left: 0;
                border-top: 0;

                & .e-headercelldiv {
                    padding-top: 0.25rem;
                }
            }
        }

        &.e-filterbar {
            height: auto;

            & th.e-filterbarcell {
                height: auto;
                background-color: var(--surface-neutral-base) !important;
                background-image: unset !important;
                border-left: 0;
                border-top: 0 !important;
                padding-top: 0 !important;
                padding-bottom: 0.75rem !important;

                & .e-filterdiv {
                    padding: 0;
                }
            }

        }
    }

    & tbody {
        & tr {
            background-color: #fff;

            & td {
                padding: 0;
            }

            & .listGridItemOutter {
                padding: 0.75rem;
                padding-bottom: 0;
                background-color: #fff;

                & .listGridItemContent {
                    border: 1px solid var(--stroke-neutral-light);

                    &.border_base {
                        border-color: var(--stroke-neutral-base);
                    }

                    & .listGridItem_separator {
                        border-top: 1px solid rgba(0, 0, 0, 0.1);
                    }
                }
            }

            &:last-child {
                & .listGridItemOutter {
                    padding-bottom: 0.75rem;
                }

            }
        }
    }

    &.e-row-responsive {
        tbody tr {
            & .listGridItemOutter {
                padding-left: 0;
                padding-right: 0;
            }

            &:last-child {
                & .listGridItemOutter {
                    padding-top: 0rem;
                }

            }
        }
    }
}

.tableValueWithHeight {
    min-height: 28px;
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 1450px) {
    .rafSFList.rafColumnList .e-list-item {
        width: 33.33%;
    }
}

@media only screen and (max-width: 1200px) {
    .rafSFList.rafColumnList .e-list-item {
        width: 50%;
    }
}

@media only screen and (max-width: 900px) {
    .rafSFList.rafColumnList .e-list-item {
        width: 100%;
    }
}

@media only screen and (max-width: 767px) {
    .rafSFList.rafColumnList .e-list-item {
        width: 50%;
    }
}

@media only screen and (max-width: 576px) {

    .rafSFList.rafColumnList .e-list-item {
        width: 100%;
    }

    .e-bigger .e-grid.e-row-responsive .e-gridcontent td .gridDefault {
        margin: 0 !important;
    }
}

@media only screen and (min-width: 576px) {
    .rafSFGrid .e-row {
        height: 34px;
        z-index: 1;
        position: relative;

    }

    .ListGrid.CardGrid.e-grid {
        & .e-gridcontent {
            & .e-content {
                & .e-table {
                    & tbody {
                        grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));

                        & tr:not(.e-emptyrow) {
                            // width: 50%;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 768px) {}

@media only screen and (min-width: 992px) {
    .ListGrid.CardGrid.e-grid {
        & .e-gridcontent {
            & .e-content {
                & .e-table {
                    & tbody {
                        & tr:not(.e-emptyrow) {
                            // width: 33.33%;
                        }
                    }
                }
            }
        }

        &.CardGrid-50 {
            & .e-gridcontent {
                & .e-content {
                    & .e-table {
                        & tbody {
                            & tr:not(.e-emptyrow) {
                                width: 50%;
                            }
                        }
                    }
                }
            }

        }
    }

}

@media only screen and (min-width: 1200px) {}

@media only screen and (min-width: 1400px) {
    .ListGrid.CardGrid.e-grid {
        & .e-gridcontent {
            & .e-content {
                & .e-table {
                    & tbody {
                        & tr:not(.e-emptyrow) {
                            // width: 25%;
                        }
                    }
                }
            }
        }

        &.CardGrid-50 {
            & .e-gridcontent {
                & .e-content {
                    & .e-table {
                        & tbody {
                            & tr:not(.e-emptyrow) {
                                width: 50%;
                            }
                        }
                    }
                }
            }

        }
    }

}

//List column grid style end